var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.saveProfile.apply(null, arguments)}}},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var passes = ref.passes;
var validate = ref.validate;
return [_c('v-card',{attrs:{"flat":"","light":""}},[_c('v-card-text',{staticClass:"pa-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('h3',{staticClass:"headline pb-2",attrs:{"color":"black"}},[_vm._v(_vm._s(_vm.$t('client_edit')))])])],1),_c('v-row',[_c('v-col',{staticClass:"py-0 mt-3 px-sm-6 text-center",attrs:{"cols":"12","md":"2","sm":"3"}},[_c('make-avatar',{attrs:{"aspect-ratio":(1/1)},model:{value:(_vm.photo),callback:function ($$v) {_vm.photo=$$v},expression:"photo"}})],1),_c('v-col',{staticClass:"pt-10  mt-0",attrs:{"cols":"12","md":"10","sm":"9"}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{ref:"lname",attrs:{"rules":"required|min:1|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"type":"text","error":!valid,"error-messages":errors,"disabled":_vm.loading,"prepend-icon":"mdi-account","label":_vm.$t('client_last_name'),"color":"primary","outlined":"","rounded":"","dense":"","clearable":""},model:{value:(_vm.lname),callback:function ($$v) {_vm.lname=$$v},expression:"lname"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{ref:"fname",attrs:{"rules":"required|min:1|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"type":"text","error":!valid,"error-messages":errors,"disabled":_vm.loading,"prepend-icon":"mdi-account","label":_vm.$t('client_first_name'),"color":"primary","outlined":"","rounded":"","dense":"","clearable":""},model:{value:(_vm.fname),callback:function ($$v) {_vm.fname=$$v},expression:"fname"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{ref:"mname",attrs:{"rules":"min:1|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"type":"text","error":!valid,"error-messages":errors,"disabled":_vm.loading,"prepend-icon":"mdi-account","label":_vm.$t('client_middle_name'),"color":"primary","outlined":"","rounded":"","dense":"","clearable":""},model:{value:(_vm.mname),callback:function ($$v) {_vm.mname=$$v},expression:"mname"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{ref:"iin",attrs:{"rules":"integer|min:12|max:12"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"type":"text","error":!valid,"error-messages":errors,"disabled":_vm.loading,"prepend-icon":"mdi-badge-account-horizontal-outline","label":_vm.$t('client_iin'),"color":"primary","outlined":"","rounded":"","dense":"","clearable":""},model:{value:(_vm.iin),callback:function ($$v) {_vm.iin=$$v},expression:"iin"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{ref:"phone",attrs:{"rules":"phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.phoneMask),expression:"phoneMask"}],attrs:{"type":"tel","error-messages":errors,"disabled":"","prepend-icon":"mdi-phone","label":_vm.$t('phone'),"color":"primary","outlined":"","rounded":"","dense":"","readonly":""},model:{value:(_vm.phoneRaw),callback:function ($$v) {_vm.phoneRaw=$$v},expression:"phoneRaw"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{ref:"email",attrs:{"rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"type":"email","error-messages":errors,"disabled":_vm.loading,"error":!valid,"prepend-icon":"mdi-email","label":_vm.$t('email'),"outlined":"","rounded":"","dense":"","color":"primary","clearable":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)})],1)],1)],1)],1),_c('v-row',{staticClass:"pt-5"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{ref:"country",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-autocomplete',{attrs:{"items":_vm.countryItems,"error":!valid,"error-messages":errors,"item-text":"name","item-value":"uuid","prepend-icon":"mdi-web","loading":_vm.loadingCountries,"disabled":_vm.loading,"label":_vm.$t('country'),"outlined":"","rounded":"","dense":"","color":"primary","return-object":"","clearable":""},on:{"click:clear":_vm.clearCountries},model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}})]}}],null,true)})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{ref:"city",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-autocomplete',{attrs:{"items":_vm.cityItems,"error":!valid,"error-messages":errors,"item-text":"name","item-value":"uuid","prepend-icon":"mdi-city","loading":_vm.loadingCities,"disabled":_vm.loading || !_vm.country,"label":_vm.$t('city'),"outlined":"","rounded":"","dense":"","color":"primary","return-object":"","clearable":"","autocomplete":"off"},on:{"click:clear":_vm.clearCities},model:{value:(_vm.city),callback:function ($$v) {_vm.city=$$v},expression:"city"}})]}}],null,true)})],1)],1)],1)],1)],1),_c('v-card-actions',{staticClass:"pt-8"},[_c('v-progress-linear',{staticClass:"mx-2",attrs:{"active":_vm.loading},model:{value:(_vm.progress),callback:function ($$v) {_vm.progress=$$v},expression:"progress"}}),_c('v-spacer'),_c('v-btn',{attrs:{"type":"submit","disabled":invalid || _vm.loading,"loading":_vm.loading,"color":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }